<template>
    <div class="h-full">
        <fixed-list :dataSource="dataSource" :config="config" @onDataSourceChange="onDataSourceChange" @onPageChange="onPageChange" :queryParam="queryParam">
            <div slot="buttonGroup" class="h-handle-button" >
                <div class="h-b"><el-button type="primary" size="small" @click="addExternalAccess" v-right-code="'Sysexternalaccess:Edit'">新增</el-button> </div>
                <div class="h-b"><el-button type="primary" size="small" @click="deleteExternalAccess" v-right-code="'Sysexternalaccess:Delete'">删除</el-button> </div>
            </div>
            <p slot="elList">
                <el-table ref="externalAccessListTable" :data="dataSource.Result" border @selection-change="handleSelectionChange" highlight-current-row>
                    <el-table-column type="selection" width="55" v-if="!config.isDetailDisplay"></el-table-column>
                    <el-table-column v-for="(col,index,count)  in dataSource.ColDefs.BodyFieldParams"
                                     :key="index"
                                     :prop="col.FieldName"
                                     :label="col.DisplayName"
                                     :render-header="bindFilter(queryParam,col)"
                                     :fixed="index==0&&(!config||!config.isDetailDisplay)"
                                     :min-width="(!config||!config.isDetailDisplay)?parseInt(col.Width):0"
                                     v-if="col.Visible&&(!config||!config.isDetailDisplay||(config.isDetailDisplay&&index<2))">
                        <template slot-scope="scope">
                            <span v-if="col.FieldName==='ExternalSystemName'"><span type="text" @click="rowClick(scope.row)" style="text-decoration:underline;color:#1874CD;cursor:pointer">{{ scope.row[col.FieldName] }}</span></span>
                            <span v-else>  {{ scope.row[col.FieldName] }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="操作" :min-width="80" v-if="!config.isDetailDisplay">
                        <template slot-scope="scope">
                            <el-button @click="setStatus(scope.row)" v-if="scope.row.ExternalAccessStatus==100" v-right-code="'Manage_Right_System_ExternalAccess_AddEdit'" size="small" type="text">停用</el-button>
                            <el-button @click="setStatus(scope.row)" v-else v-right-code="'Manage_Right_System_ExternalAccess_AddEdit'" size="small" type="text">启用</el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </p>
        </fixed-list>
    </div>
</template>
<script>
    export default {
        mounted() {
            this.Event.$on("reloadExternalAccessPageList", () => this.reloadPageList());
            this.Event.$on("onAddExternalAccess", () => this.addExternalAccess());
            this.initialize();
        },
        data() {
            return {

                multipleSelection: [],
                queryParam: {
                    PageIndex: 1,
                    PageSize: 10
                },
                dataSource: {
                    ColDefs: {},
                    Result: [],
                    TotalCount: 0
                },

            }
        },

        props: {
            config: {
                isDetailDisplay: false,
                isButtonlDisplay: false,
            },
            option: {},
        },
        methods: {
            reloadPageList: function () {
                this.initialize();
            },
            initialize() {
                this.onPageChange(this.queryParam);
            },
            handleSelectionChange(val) {
                this.multipleSelection = val;
                this.$emit("onCheckRow", this.multipleSelection);
            },
            onPageChange(param) {
                var _this = this;
                this.queryParam = param;
                this.$ajax.query("omsapi/sysexternalaccess/searchexternalaccesslist", "post", param, data => {
                    _this.dataSource = data;
                });
            },
            onDataSourceChange(ds) {
                var _this = this;

                _this.dataSource = {
                    ColDefs: {
                        BodyFieldParams: []
                    },
                    Result: [],
                    TotalCount: 0
                };
                _this.$nextTick(function () {
                    _this.dataSource = ds;

                });

            },
            rowClick(row) {
                this.$ajax.send("omsapi/sysexternalaccess/getexternalaccessbyid", "get", { id: row.AccessID }, (data) => {
                    this.Event.$emit("clearEditExternalAccessForm");
					if (data.Result.MemberId==null||data.Result.MemberId == 0) {
						data.Result.MemberId = "";
					}
                    this.onChangeEditDataSource(data.Result);
                });
            },
            setStatus(row) {
                var _this = this;
                var tips = row.ExternalAccessStatus == 100 ? '停用' : '启用';
                this.$confirm(`确定${tips}此系统接入吗, 是否继续?`, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.$ajax.send("omsapi/sysexternalaccess/setexternalstatus", "post", row, (data) => {
                        if (data.IsSuccess) {
                            _this.Utils.messageBox(data.OperationDesc, "success");
                            _this.initialize();
                        } else {
                            _this.Utils.messageBox(data.OperationDesc, "error");
                        }
                    });
                }).catch(() => {
                });
                _this.initialize();
            },
            addExternalAccess() {
                this.$ajax.send("omsapi/sysexternalaccess/getemptyexternalaccess", "get", {}, (data) => {
                    if (data.Result != null){
                        data.Result.ExternalSystemID = null;
                        data.Result.OptEnterpriseID = null;
                        data.Result.MemberId = null;
                        data.Result.ExternalSystemType = null;
                    }
                    
                    this.onChangeEditDataSource(data.Result);
                });
            },   
            deleteExternalAccess() {
                var _this = this;
                if (_this.multipleSelection.length <= 0) {
                    _this.Utils.messageBox("请至少选择一条数据");
                    return;
                }
                this.$confirm('确定要删除选中的数据吗, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.$ajax.send("omsapi/sysexternalaccess/delete", "post", this.multipleSelection, (data) => {
                        if (data.IsSuccess) {
                            _this.Utils.messageBox("操作成功", "success");
                            _this.initialize();
                        } else {
                            _this.Utils.messageBox(data.OperationDesc, "error");
                        }
                    });
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消'
                    });
                });
            },
            onChangeEditDataSource(data) {
                this.config.isDetailDisplay = true;                
                this.$emit("onChangeEditDataSource", data);
            },

        },

    }
</script>
<style>

</style>